<template>
  <div class="ps-goals">
    <div class="one-title-drop">
      <div>
<!--        {{ year }}/{{ month }}-->
        <van-dropdown-menu active-color="#3F7C53" class="width-130">
          <van-dropdown-item
                  v-model="targetDate"
                  :options="dateList"
                  @change="dateChange"
          />
        </van-dropdown-menu>
      </div>
      <div>
        <van-dropdown-menu active-color="#3F7C53" class="width-130">
          <van-dropdown-item
            v-model="deptNo"
            :options="dropList"
            @change="changeDept"
          />
        </van-dropdown-menu>
      </div>
    </div>

    <van-tabs v-model="active" color="#3f7c53" @change="tabChange">
      <van-tab v-for="item in tabList" :title="item.title" :key="item.value">
        <notTitleGoals :cityData="cityData" :tabType="stationType" v-if="!loadingCityData">
        </notTitleGoals>

<!--        <van-tabs color="#3f7c53" @click="changeRankType" >-->
<!--          <van-tab title="地区" key="0"> </van-tab>-->
<!--          <van-tab title="人员" key="1"> </van-tab>-->
<!--        </van-tabs>-->

        <div class="div-tab">
          <van-row>
            <van-col span="12" v-for="(tb,idx) in tabchild"
                     :key="tb.value">
              <div class="flex justify_content_center" @click="changeRankType(idx)">
                <span :style="tabvalue===idx?'border-bottom:3px solid #3f7c53':''">{{tb.text}}</span>
              </div>
            </van-col>
          </van-row>
        </div>



        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="sub-goals">
              <hasTitleGoals :dataList="dataList" :tabType="stationType" :rankingType="tabvalue===1?'PERSON':'AREA'">
              </hasTitleGoals>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import hasTitleGoals from "@/components/hasTitleGoals";
import notTitleGoals from "@/components/notTitleGoals";
import { getPerformanceRanking } from "@/utils/goals";
import common from "@/utils/common";
import moment from 'moment';
export default {
  name: "highGoals",
  components: { hasTitleGoals, notTitleGoals },
  data() {
    return {
      dropList: common.getItem("proviceArr"),
      deptNo: common.getItem("proviceArr")[0].value, //运营部的code
      year:new Date().getFullYear(), //获取当前年  moment().date(10).format('YYYY-MM-DD')
      month: new Date().getMonth() + 1, //获取当前月
      currentRate: 0,
      active: 0, //默认新建站点
      value: "淄博运营部",
      showPicker: false,
      tabList: [
        { title: "新建站点", value: "NewlyStation" },
        { title: "有效站点", value: "ActiveStation" },
        { title: "双百站点", value: "DoubleHundred" },
        { title: "时点余额", value: "PointBalance" },
        //{ title: "达标站点", value: "ReachToQs" },
          {title:'上线站点',value:'onlineStation'}
      ],
      tabEnume: {
        //tab枚举
        0: "NewlyStation",
        1: "ActiveStation",
        2: "DoubleHundred",
        3: "PointBalance",
        4: "onlineStation",
      },
      stationType: "NewlyStation", //tab类型
      page: 1,
      size: 10,
      loadingCityData: false,
      cityData: {},
      dataList: [],

      rankType: "AREA", //默认是地区排名
      isLoading: false,
      loading: false,
      finished: false,
      tabvalue:0,
      tabchild:[
        {text:'地区',value:0},
        {text:'人员',value:1}
      ],
      dateList:[
          {text:moment().subtract(3,'months').format('YYYY/MM'),
              value:moment().subtract(3,'months').format('YYYY/MM')},
          {text:moment().subtract(2,'months').format('YYYY/MM'),
              value:moment().subtract(2,'months').format('YYYY/MM')},
        {text:moment().subtract(1,'months').format('YYYY/MM'),
            value:moment().subtract(1,'months').format('YYYY/MM')},
        {text:moment().format('YYYY/MM'),value: moment().format('YYYY/MM')},
      ],
      //每月10号前显示上月,否则显示本月
      targetDate:moment().format("YYYY-MM-DD")>moment().date(10).format('YYYY-MM-DD') ?
        moment().format('YYYY/MM'):
        moment().subtract(1,'months').format('YYYY/MM'),
      };
  },
  methods: {
    //时间下拉框
    dateChange(){
      this.page = 1;
      this.dataList = [];
      this.cityData = {};
      this.seachData();
    },
    //下拉框
    changeDept() {
      this.page = 1;
      this.dataList = [];
      this.cityData = {};
      this.seachData();
    },
    //切换tab类型
    tabChange(value){
      this.tabvalue = 0;
      this.stationType = this.tabEnume[value];
      this.page = 1;
      this.dataList = [];
      this.cityData = {};
      this.seachData();
    },
    //切换地区/人员类型
    changeRankType(value){
      console.log('value的值', value)
      this.tabvalue = value;
      // if(value===0){
      //   this.rankType = 'AREA'
      // }
      // if(value===1){
      //   this.rankType = 'PERSON'
      // }
      this.page=1;
      this.dataList = [];
      this.seachData();
    },

    onLoad() {
      this.seachData();
    },
    //查询业绩目标与达成
    seachData() {
      let data = {
        page:this.page,
        size:this.size,
        targetDate:this.targetDate, //this.year+'/'+this.month,
        rankingLevel: 'PROVINCE',
        rankingType:this.tabvalue===0?'AREA':this.tabvalue===1?'PERSON':'', //this.rankType,//地区排名：AREA；人员排名：PERSON
        objNo:this.deptNo,
        stationType:this.stationType,
      }
      this.loadingCityData = true
      getPerformanceRanking(data).then(res=>{
        this.loading = false;
        this.loadingCityData = false
        if (res.code === 200 && res.data.businessCode === 200) {
          this.finished = !res.data.rankList.hasNextPage;
          this.cityData = res.data.rankList.current;

          if (this.page === 1) {
            this.dataList = res.data.rankList.rankingList;
          } else {
            this.dataList = this.dataList.concat(res.data.rankList.rankingList);
          }
            console.log('dataList',this.dataList);
          if (!this.finished) {
            this.page++;
          }
        }
        if (!res.data.rankList) {
          //如果没有数据
          this.finished = true;
        }
      });
    },
    //下拉刷新
    onRefresh() {
      this.isLoading = false;
      this.dataList = [];
      this.page = 1;
      this.seachData();
    },
  },
};
</script>

<style lang="less">
@import "../../style/goals";

.ps-goals {
  min-height: 100%;
  background: #f2f2f2;
  padding-bottom: 1.46rem;
  position: relative;

  .opera-title {
    display: flex;
    align-items: center;
    padding: 0.1rem 0.3rem;
    background: #ffffff;
    border-bottom: 1px solid #e5e5e5;
  }
  .just-between {
    width: 100%;
    justify-content: space-between;
  }
  .align-center {
    align-items: center;
  }
  .icon-triangle {
    width: 0;
    height: 0;
    border-left: 0.06rem solid transparent;
    border-right: 0.06rem solid transparent;
    border-top: 0.12rem solid #3f7c53;
    margin-left: 0.2rem;
  }
  .botton-box {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.46rem;
    padding: 0.24rem;
    background: #ffffff;
  }
  .div-tab{
    width: 100%;
    height: 40px;
    line-height: 35px;
    background:#fff;
  }
}
</style>
